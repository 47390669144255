.gmaps-area {
    min-height: 400px;
    height: 100%;
    width: 100%;
}

.gmaps-area #gmaps {
    width: 100%;
}

.contact-section {
    position: relative;
}

.contact-wrapper {
    padding-right: 30px;
    @include breakpoint(max-lg) {
        padding-right: 0;
    }
}

.info-item {
    padding: 40px;
    border-radius: 7px;
    background: $body-bg;
    align-items: start;
    @extend %flex;
    height: 100%;
    @include breakpoint(max-xs) {
        padding: 25px 20px;
    }
    .info-icon {
        width: 50px;
        aspect-ratio: 1;
        @extend %flex-center;
        background: $base-color;
        color: $white;
        border-radius: 5px;
        font-size: 24px;
    }
    .info-content {
        width: calc(100% - 50px);
        padding-left: 25px;
    }
    a {
        display: block;
    }
}
