.pagination {
    margin: 0;
    @extend %flex;
    justify-content: center;
    .page-item {
        text-align: center;
        padding: 3px;
        a,
        span {
            font-weight: 600;
            width: 45px;
            height: 45px;
            color: $base-color;
            line-height: 45px;
            padding: 0;
            border-radius: 50% !important;
            border-color: rgba($base-color, 0.2);
            background: $section-bg;
            box-shadow: none;
            outline: 0;
        }
        span {
            border: none;
        }
        &.active,
        &:hover {
            span,
            a {
                background: $base-color;
                color: $white;
                border-color: rgba($base-color, 1);
            }
        }
        &.disabled {
            cursor: no-drop !important;
            span,
            a {
                background: rgba($white, 0.05);
                border: none;
                color: $title-color;
            }
        }
    }
}
