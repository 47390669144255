.section-header {
    margin-top: -10px;
    margin-bottom: 50px;
    @include breakpoint(max-sm) {
        margin-bottom: 40px;
    }
    &__title {
        margin-bottom: 15px;
        span {
            position: relative;
            color: $base-color;
            &::before {
                @extend %position;
                bottom: 0;
                left: 0;
                background: $base-color;
                height: 2px;
            }
        }
    }
    p {
        font-size: 22px;
        font-weight: 300;
        @include breakpoint(max-xl) {
            font-size: 20px;
        }
        @include breakpoint(max-sm) {
            font-size: 18px;
        }
    }
}
.particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

// What Section Starts Here
.rtl {
    direction: rtl;
}
.what-item {
    text-align: center;
    padding: 40px 20px;
    background: $body-bg;
    &:hover {
        .title {
            color: $base-color;
        }
    }
    &__icon {
        font-size: 48px;
        margin-bottom: 10px;
        color: $base-color;
    }
    &__content {
        .title {
            margin-bottom: 15px;
        }
    }
}
// What Section Ends Here

// About Section Starts Here
.about-thumb {
    position: relative;
    .shape {
        position: absolute;
        left: -50px;
        bottom: -50px;
        right: 50px;
        top: -30px;
        z-index: -1;
        @include breakpoint(max-xl) {
            left: -30px;
            bottom: -30px;
            top: 0;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    img {
        width: 100%;
    }
    .video-button {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
.feature-wrapper {
    padding: 15px 20px;
    border-radius: 3px;
    background: $section-bg;
    @extend %flex;
}
.feature-item {
    @extend %flex;
    padding: 10px 5px;
    @include breakpoint(sm) {
        width: 50%;
    }
    &__icon {
        font-size: 20px;
        color: $base-color;
        line-height: 1.2;
        margin-right: 10px;
    }
    .title {
        font-size: 17px;
        @include breakpoint(max-xxl) {
            font-size: 16px;
        }
        @include breakpoint(max-xxl) {
            font-size: 14px;
        }
    }
}
// About Section Ends Here

#chart,
.chart-box {
    padding-top: 20px;
    color: $white;
}
#chart *,
.chart-box * {
    color: $white;
}
.chart-value-wrapper {
    gap: 15px 45px;
    .value {
        @include breakpoint(max-xs) {
            font-size: 15px;
        }
    }
}

// Timeline Section Starts Here
.timeline-wrapper {
    @extend %flex;
    max-width: 860px;
    margin: 0 auto;
    position: relative;
    &::before {
        @extend %position;
        width: 15px;
        height: calc(100% + 70px);
        background: rgba($accent-color, 0.5);
        left: 50%;
        top: 60px;
        border-radius: 15px;
        transform: translateX(-50%);
        @include breakpoint(max-lg) {
            height: calc(100% + 30px);
        }
        @include breakpoint(max-md) {
            height: calc(100% - 70px);
        }
    }
}
.timeline-item {
    max-width: 300px;
    height: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 50px;
    @include breakpoint(max-lg) {
        max-width: 280px;
    }
    .timeline-header {
        padding: 7px 25px;
        background: $theme-one;
        border-radius: 0 25px 25px 0;
        position: relative;
        box-shadow: 0 6px 2px 1px rgba($dark, 0.4);
        z-index: 3;
        @include breakpoint(max-xs) {
            padding: 6px 20px;
        }
        &::before {
            @extend %position;
            left: -30px;
            top: 0;
            width: 30px;
            height: calc(100% + 30px);
            background: $theme-one;
            border-radius: 35px 0 0 0;
            z-index: -3;
            @include breakpoint(max-xs) {
                width: 20px;
                left: -17px;
            }
        }
        &::after {
            @extend %position;
            left: -30px;
            top: 45px;
            width: 60px;
            height: 60px;
            background: darken($theme-one, 20%);
            border-radius: 35px 0 0 0;
            border-radius: 50%;
            z-index: -2;
            box-shadow: inset 1px 6px 2px 2px rgba($dark, 0.3);
            @include breakpoint(max-xs) {
                width: 45px;
                height: 45px;
                left: -18px;
                top: 38px;
            }
        }
    }
    .timeline-body {
        background: linear-gradient(50deg, $section-bg, $body-bg);
        position: relative;
        z-index: 3;
        padding: 25px;
        border-radius: 0 35px 25px 25px;
        box-shadow: inset 4px -6px 2px 2px rgba($dark, 0.3);
        .title {
            margin-bottom: 10px;
        }
        &::before {
            @extend %position;
            box-shadow: inset 0 7px 2px rgba($dark, 0.3);
            left: 0;
            bottom: 0;
            width: calc(100% - 18px);
        }
    }
    .timeline-icon {
        position: absolute;
        font-size: 24px;
        left: calc(100% + 100px);
        bottom: 60px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $theme-one;
        border: 4px solid rgba($white, 0.25);
        @include breakpoint(max-lg) {
            left: calc(100% + 20px);
        }
        @include breakpoint(max-sm) {
            display: none;
        }
        &::before {
            @extend %position;
            height: 0px;
            border: 2px dashed $theme-one;
            width: 100px;
            right: calc(100% + 5px);
        }
    }

    &:nth-child(even) {
        @include breakpoint(md) {
            transform: translateY(50%);
        }
        .timeline-header {
            border-radius: 25px 0 0 25px;
            &::before {
                @extend %position;
                right: -30px;
                left: auto;
                top: 0;
                width: 30px;
                height: calc(100% + 30px);
                border-radius: 0 35px 0 0;
                z-index: -3;
                @include breakpoint(max-xs) {
                    width: 20px;
                    right: -17px;
                }
            }
            &::after {
                @extend %position;
                right: -30px;
                left: auto;
                top: 45px;
                width: 60px;
                height: 60px;
                border-radius: 35px 0 0 0;
                border-radius: 50%;
                z-index: -2;
                @include breakpoint(max-xs) {
                    width: 45px;
                    height: 45px;
                    right: -18px;
                    top: 38px;
                }
            }
        }
        .timeline-body {
            background: linear-gradient(-50deg, $section-bg, $body-bg);
            border-radius: 35px 0 25px 25px;
            box-shadow: inset -4px -6px 2px 2px rgba($dark, 0.3);
            &::before {
                box-shadow: inset 0 7px 2px rgba($dark, 0.3);
                left: 18px;
            }
        }
        .timeline-icon {
            left: auto;
            right: calc(100% + 100px);
            @include breakpoint(max-lg) {
                right: calc(100% + 20px);
            }
            &::before {
                right: auto;
                left: calc(100% + 5px);
            }
        }
    }
    &:nth-of-type(4n + 2) {
        .timeline-header {
            background: $theme-two;
            &::before,
            &::after {
                background: $theme-two;
            }
            &::after {
                background: darken($theme-two, 15%);
            }
        }
        .timeline-icon {
            background: $theme-two;
            &::before {
                border-color: $theme-two;
            }
        }
    }
    &:nth-of-type(4n + 3) {
        .timeline-header {
            background: $theme-three;
            &::before,
            &::after {
                background: $theme-three;
            }
            &::after {
                background: darken($theme-three, 15%);
            }
        }
        .timeline-icon {
            background: $theme-three;
            &::before {
                border-color: $theme-three;
            }
        }
    }
    &:nth-of-type(4n + 4) {
        .timeline-header {
            background: $theme-four;
            &::before,
            &::after {
                background: $theme-four;
            }
            &::after {
                background: darken($theme-four, 15%);
            }
        }
        .timeline-icon {
            background: $theme-four;
            &::before {
                border-color: $theme-four;
            }
        }
    }
}
// Timeline Section Ends Here

// Faq Section Starts Here
.faq-item {
    border-radius: 15px;
    background: $body-bg;
    &:not(&:last-child) {
        margin-bottom: 25px;
    }
    &.active {
        .faq-item__content {
            display: block;
        }
    }
    &.open {
        .faq-item__title .title {
            color: $base-color;
        }
        .faq-item__title::before {
            transform: rotate(90deg);
        }
    }
    &__title {
        padding: 15px 20px;
        cursor: pointer;
        position: relative;
        &::before,
        &::after {
            @extend %position;
            background: $base-color;
            width: 2px;
            height: 20px;
            top: 16px;
            right: 19px;
            @extend %transition;
        }
        &::after {
            width: 20px;
            height: 2px;
            right: 10px;
            top: 25px;
        }
        .title {
            padding-right: 25px;
            @extend %transition;
        }
    }
    &__content {
        padding: 10px 20px 25px;
        display: none;
    }
}
.faq-section.bg--body {
    .faq-item {
        background: $section-bg !important;
    }
}
// Faq Section Ends Here

// Testimonial Section Starts Here
.testimonial-slider {
    margin-right: -30px;
    .single-slide {
        padding-right: 30px;
    }
}
.slick-dots {
    bottom: -50px;
}
.slick-dots li button {
    background: rgba($base-color, 0.3);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: relative;
    &::before {
        @extend %position;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 1px solid rgba($base-color, 0.8);
        border-radius: 50%;
        @extend %transition;
    }
}
.slick-dots li.slick-active button {
    background: rgba($base-color, 1);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    &::before {
        transform: translate(-50%, -50%) scale(2.5);
    }
}
.testimonial-item {
    @extend %flex;
    &__thumb {
        width: 150px;
        height: 150px;
        position: relative;
        @include breakpoint(max-xl) {
            @include breakpoint(lg) {
                width: 120px;
                height: 120px;
            }
            @include breakpoint(max-md) {
                width: 130px;
                height: 130px;
            }
            @include breakpoint(max-sm) {
                margin: 0 auto;
            }
        }
        .quote-icon {
            position: absolute;
            right: -15px;
            top: -15px;
            font-size: 64px;
            line-height: 1;
            color: $base-color;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    &__content {
        width: calc(100% - 150px);
        padding-left: 25px;
        @include breakpoint(max-sm) {
            width: 100%;
            padding-left: 0;
            padding-top: 15px;
            text-align: center;
        }
        .name {
            margin-bottom: 10px;
        }
        .designation {
            margin-bottom: 15px;
        }
    }
}
// Testimonial Section Ends Here

// Blog Section Starts Here
div[class*="col"] {
    &:nth-of-type(3n + 1) {
        .post-item {
            &__content {
                .tag {
                    background: $base-color;
                }
            }
        }
    }
    &:nth-of-type(3n + 2) {
        .post-item {
            &__content {
                .tag {
                    background: $theme-four;
                }
            }
        }
    }
    &:nth-of-type(3n + 3) {
        .post-item {
            &__content {
                .tag {
                    background: $theme-three;
                }
            }
        }
    }
}
.post-item {
    padding: 12px;
    border-radius: 15px;
    background: $section-bg;
    &:hover {
        .post-item__thumb img {
            transform: scale(1.1);
        }
        .title a {
            color: $base-color;
        }
    }
    &__thumb {
        position: relative;
        height: 270px;
        overflow: hidden;
        border-radius: 12px;
        @include breakpoint(max-xl) {
            height: 240px;
        }
        a {
            display: block;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
        }
    }
    &__content {
        padding: 40px 15px 10px;
        position: relative;
        .tag {
            position: absolute;
            top: 0;
            left: 30px;
            transform: translateY(-50%);
            width: 60px;
            aspect-ratio: 1;
            font-size: 24px;
            border: 4px solid rgba($white, 0.4);
            background: $base-color;
            @extend %flex-center;
            border-radius: 50%;
        }
        .title {
            margin-bottom: 12px;
            a {
                @include text-wrap;
            }
        }
        p {
            @include text-wrap;
            -webkit-line-clamp: 3;
        }
        .read-more {
            margin-top: 15px;
        }
    }
}
.read-more {
    text-decoration: underline;
    font-size: 18px;
    font-weight: 500;
}
// Blog Section Ends Here

// Blog Details Section Stars Here
.blog-details-thumb img {
    width: 100%;
}

.blog-details-content p {
    margin-bottom: 25px;
}

.blog-details-content blockquote {
    background: $section-bg;
    padding: 25px 20px;
    border-left: 2px solid $white;
    font-style: italic;
    color: $white;
}

.blog-details-header {
    margin-top: 25px;
}

.blog-details-header .tag i {
    margin-right: 4px;
    font-size: 14px;
}

.blog-details-header .meta-1 {
    margin: 10px 0;
}

.blog-details-header .meta-1 li {
    color: $white;
    margin-right: 25px;
    font-size: 14px;
}

.blog-details-header .meta-1 li a {
    color: $base-color;
}

.blog-details-header .meta-1 li i {
    color: $base-color;
    font-size: 13px;
    margin-right: 4px;
}
.recent-post-wrapper > .title {
    position: relative;
    padding-bottom: 8px;
    &::before {
        @extend %position-set;
        background: $base-color;
        height: 2px;
        top: auto;
        bottom: 0;
    }
}
.recent-post-wrapper {
    @extend %flex;
    width: 100%;
}
.recent-blog {
    @extend %flex;
    align-items: center;
    margin-bottom: 25px;
    @include breakpoint(max-lg) {
        @include breakpoint(md) {
            width: 50%;
            &:nth-child(odd) {
                padding-left: 15px;
            }
            &:nth-child(even) {
                padding-right: 15px;
            }
        }
    }
}

.recent-blog:last-child {
    margin-bottom: 0;
}

.recent-blog .blog__thumb {
    width: 100px;
}
.recent-blog .blog__thumb img {
    width: 100%;
}

.recent-blog .blog__content {
    width: calc(100% - 100px);
    padding: 0;
    padding-left: 15px;
    border: none;
}
.recent-blog .blog__content .title {
    font-size: 16px;
}
.recent-blog .blog__content .date {
    font-size: 14px;
    font-weight: 500;
    color: $base-color;
}

.blog-sidebar {
    position: sticky;
    top: 120px;
    @include breakpoint(xl) {
        padding-left: 30px;
    }
}
// Blog Details Section Ends Here

// Search Section Starts Here
.search-header {
    gap: 10px 30px;
    li {
        &:first-child {
            position: relative;
            padding-right: 30px;
            &::before {
                @extend %line-ico;
                content: "\f061";
                right: 0;
                top: 4px;
            }
        }
    }
}

.search-section .search-wrapper {
    max-width: 700px;
    margin: 0 auto;
    .form--control {
        height: 50px;
        background: $white;
    }
}
// Search Section Ends Here
