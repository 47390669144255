h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 700;
    line-height: 1.3;
    color: $title-color;
    font-family: $heading;
    word-break: break-word;
}
h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
    color: $title-color;
    word-break: break-word;
}
h1 {
    font-size: 64px;
    @include breakpoint(max-xl) {
        font-size: 50px;
    }
    @include breakpoint(max-md) {
        font-size: 48px;
    }
    @include breakpoint(max-sm) {
        font-size: 36px;
    }
}
h2 {
    font-size: 48px;
    @include breakpoint(max-xl) {
        font-size: 42px;
    }
    @include breakpoint(max-md) {
        font-size: 36px;
    }
    @include breakpoint(max-sm) {
        font-size: 32px;
    }
}

h3 {
    font-size: clamp(22px, 5vw, 30px);
}
h4 {
    font-size: 24px;
    font-weight: 500;
    @include breakpoint(max-xxl) {
        font-size: 23px;
    }
    @include breakpoint(max-xs) {
        font-size: 20px;
    }
}
h5 {
    font-size: 20px;
    font-weight: 500;
    @include breakpoint(max-md) {
        font-size: 18px;
    }
}
h6 {
    font-size: 18px;
    font-weight: 500;
}

.fw-medium {
    font-weight: 500 !important;
}
