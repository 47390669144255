.table--responsive--md {
    @include breakpoint(max-md) {
        thead {
            display: none;
        }
        tbody {
            tr {
                display: block;
                td {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 15px;
                    border: none;
                    border-bottom: 1px solid $border-color;
                    @include breakpoint(max-lg) {
                        font-size: 14px !important;
                    }
                    &:last-child {
                        border: none;
                    }
                    &::before {
                        content: attr(data-label);
                        font-family: $heading;
                        font-size: 16px;
                        color: $title-color;
                        font-weight: 500;
                        @include breakpoint(max-sm) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
.table--responsive--lg {
    @include breakpoint(max-lg) {
        thead {
            display: none;
        }
        tbody {
            tr {
                display: block;
                td {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 15px;
                    border: none;
                    border-bottom: 1px solid $border-color;
                    @include breakpoint(max-lg) {
                        font-size: 14px !important;
                    }
                    &:last-child {
                        border: none;
                    }
                    &::before {
                        content: attr(data-label);
                        font-family: $heading;
                        font-size: 16px;
                        color: $title-color;
                        font-weight: 500;
                        @include breakpoint(max-sm) {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
.table--responsive--xl {
    @include breakpoint(max-xl) {
        thead {
            display: none;
        }
        tbody {
            tr {
                display: block;
                td {
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 15px;
                    border: none;
                    border-bottom: 1px solid $border-color;
                    @include breakpoint(max-lg) {
                        font-size: 14px !important;
                    }
                    &:last-child {
                        border: none;
                    }
                    &::before {
                        content: attr(data-label);
                        font-family: $heading;
                        font-size: 14px;
                        color: $title-color;
                        font-weight: 500;
                        @include breakpoint(max-xs) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
}

.table {
    margin: 0;
    box-shadow: 0 5px 15px rgba($base-color, 0.2);
    & > :not(:first-child) {
        border-top: 0;
    }
    & > :not(caption) > * > * {
        border-bottom-width: 0;
    }
    thead {
        tr {
            &:first-child {
                th:first-child {
                    border-radius: 10px 0 0 0;
                    text-align: left;
                }
            }
            &:last-child {
                th:last-child {
                    border-radius: 0 10px 0 0;
                    text-align: right;
                }
            }
            th {
                background: $base-color;
                padding: 16px 15px;
                color: $white;
                border: none;
                font-size: 15px;
                font-weight: 500;
                border: 0 !important;
                text-align: center;
            }
        }
    }
    tbody {
        font-size: 15px;
        tr {
            &:nth-child(odd) {
                background: $section-bg;
            }
            &:last-child {
                td {
                    &:first-child {
                        border-radius: 0 0 0 10px;
                    }
                    &:last-child {
                        border-radius: 0 0 10px 0;
                    }
                }
            }
            td {
                color: $white;
                vertical-align: middle;
                padding: 15px 15px;
                border-width: 1px;
                text-align: center;
                @include breakpoint(xl) {
                    border-color: transparent;
                }
                &:first-child {
                    text-align: left;
                }
                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}
