.text--primary {
  color: $primary !important;
}
.text--secondary {
  color: $secondary !important;
}
.text--success {
  color: $success !important;
}
.text--danger {
  color: $danger !important;
}
.text--warning {
  color: $warning !important;
}
.text--info {
  color: $info !important;
}
.text--dark {
  color: $dark !important;
}
.text--muted {
  color: $muted !important;
}
.text--base {
  color: $base-color !important;
}
.text--dark {
  color: $title-color !important;
}