@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

@import "./_reset";

/* global css strat */
@import "./_color";
@import "./_bg";

.bg_img {
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}
.bg_fixed {
    background-attachment: fixed !important;
}
a.text-white {
    &:hover {
        color: $base-color !important;
    }
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -999;
    &.active {
        z-index: 111;
    }
}

/* width */
::-webkit-scrollbar {
    width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: $accent-color;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $base-color
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: darken($color: $base-color, $amount: 5%)
  }
