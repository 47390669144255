.button-wrapper {
    @extend %flex;
    gap: 15px;
}
.cmn--btn {
    background: $base-color;
    border: 2px solid $base-color;
    &:hover,
    &.active {
        color: $base-color;
        background: transparent;
        border-color: $base-color;
    }
}
.btn,
.cmn--btn {
    color: $white;

    padding: 12px 35px;
    font-size: 18px;
    font-weight: 600;
    font-family: $heading;
    outline: none;
    @extend %transition;
    border-radius: 8px;
    z-index: 1;
    &:hover {
        color: $white
    }
    @include breakpoint(max-xl) {
        font-size: 16px;
    }

    &.btn--lg {
        padding: 13px 35px !important;
        font-size: 24px;
        @include breakpoint(max-lg) {
            font-size: 22px;
        }
        @include breakpoint(max-sm) {
            font-size: 20px;
        }
    }
    &.btn--md {
        padding: 10px 25px !important;
        font-size: 15px;
        border-radius: 5px;
    }
    &.btn--sm {
        padding: 5px 15px !important;
        font-size: 14px;
        border-radius: 5px;
    }
    &.btn--xs {
        padding: 0px 10px !important;
        font-size: 13px;
        border-radius: 4px;
    }
}
.video-button {
    width: 60px;
    aspect-ratio: 1;
    @extend %flex-center;
    font-size: 24px;
    position: relative;
    background: $base-color;
    border-radius: 20px 0 20px 0;
    &::before,
    &::after {
        @extend %position;
        left: 50%;
        top: 50%;
        background: $base-color;
        border-radius: 20px 0 20px 0;
        animation: play-button 2s linear infinite;
    }
    &::after {
        animation-delay: 0.8s;
    }

    i {
        position: relative;
        z-index: 1;
        color: $white;
    }
}

$colors: "primary" $primary, "secondary" $secondary, "success" $success, "danger" $danger,
    "warning" $warning, "info" $info, "light" $light, "dark" $dark, "base" $base-color;

@each $name, $color in $colors {
    .btn--#{$name} {
        background-color: $color;
        border: 1px solid $color;
        color: $white;
    }
}
