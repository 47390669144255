.footer-links {
    font-size: 15px;
    li {
        padding: 5px 0;
        a {
            position: relative;
            padding-left: 20px;
            &::before {
                position: absolute;
                content: "\f101";
                font-family: "Line Awesome Free";
                font-weight: 900;
                font-size: 15px;
                left: 0;
                top: 1px;
            }
        }
    }
}

.footer-widget {
    font-size: 14px;
}

.footer {
    position: relative;
    overflow: hidden;
    .shape {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
}
.footer-contact {
    li {
        padding: 5px 0;
        i {
            margin-right: 5px;
        }
    }
}
.footer-top {
    padding: 70px 0 40px;
    @include breakpoint(max-sm) {
        padding: 50px 0 30px;
        @media (min-width: 450px) {
            .col-sm-5 {
                width: 50%;
            }
        }
    }
}
.footer-bottom-wrapper {
    padding: 40px 0 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    gap: 15px 25px;
    @include breakpoint(max-sm) {
        padding: 30px 0 50px;
        font-size: 15px;
    }
    .links {
        gap: 5px 15px;
    }
}

