html {
    scroll-behavior: smooth;
}
body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    color: $body-color;
    line-height: 1.6;
    font-family: $body;
    background: $body-bg;
    word-wrap: break-word;
}
p {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: $body-color;
    @extend %transition;
    &:hover {
        color: $base-color;
    }
}
img,
.title,
.name {
    @extend %transition;
}
span,
sub,
sup,
a {
    display: inline-block;
}
