.form-label {
    font-size: 15px;
}
.form--control {
    height: 50px;
    border-radius: 7px;
    border: 2px solid rgba($white, 0.2);
    box-shadow: none;
    color: $white;
    background-color: transparent;
    &:focus {
        color: $white;
        background-color: transparent;
        border: 2px solid rgba($base-color, 1);
        box-shadow: none;
        outline: 0;
    }
}

.form--control::file-selector-button {
    background: $base-color;
    color: $white;
    border-radius: 3px;
}
.form--control:hover::file-selector-button {
    background: darken($base-color, 5%) !important;
    color: $white;
    border-radius: 3px;
}

.input-group-text {
    background: $body-bg;
    border:2px solid $border-color;
    color: $white;
}
.input-group:focus-within {
    .input-group-text {
        border-color: $base-color;
    }
}
.form--control::placeholder {
    color: rgba($white, 0.6);
}
textarea.form--control {
    height: 140px;
}

.border--round {
    border-radius: 35px;
}
.form--control.style--dark {
    height: 50px;
    outline: 0;
    box-shadow: none;
    color: $dark;
    background-color: $white;
}
.form--control.style--dark::-webkit-input-placeholder {
    color: $dark;
}
.form--control[type="file"] {
    line-height: 25px;
    padding: 12px 25px;
}
.form--control[readonly] {
    background: rgba($primary, 0.05) !important;
    &:focus {
        border-color: $border-color;
    }
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: $title-color;
    transition: background-color 5000s ease-in-out 0s;
}
select {
    option {
        color: #363636;
    }
}
.input-group > .form--control,
.input-group > .select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.input-group {
    select {
        background-color: transparent;
        border: none;
    }
}
.custom--radio {
    position: relative;
    padding-left: 0;
    input[type="radio"] {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        cursor: pointer;
        &:checked {
            ~ label {
                &::before {
                    border-width: 2px;
                    border-color: $base-color;
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    label {
        margin-bottom: 0;
        position: relative;
        padding-left: 20px;
        font-size: rem(14px);
        font-weight: 400;
        &::before {
            position: absolute;
            content: "";
            top: 4px;
            left: 0;
            width: 15px;
            height: 15px;
            border: 1px solid #888888;
        }
        &::after {
            position: absolute;
            content: "";
            top: 8px;
            left: 4px;
            width: 7px;
            height: 7px;
            background-color: $base-color;
            opacity: 0;
        }
    }
}
.custom--checkbox {
    padding-left: 25px;
    input {
        display: none;
        &:checked ~ label::before {
            content: "\f14a" !important;
            color: $base-color;
        }
    }
    label {
        position: relative;
        margin-bottom: 0;
        &::before {
            position: absolute;
            content: "\f04d";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: 3px;
            left: -25px;
            font-size: 20px;
            line-height: 1;
            @extend %transition;
        }
    }
}
/* form css end*/
