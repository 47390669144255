.dashboard-section {
    @extend %flex;
}
.dashboard-sidebar {
    max-width: 300px;
    width: 100%;
    background-color: lighten($body-bg, 5%);
    height: 100vh;
    @extend %transition;
    z-index: 1;
    position: sticky;
    top: 0;
    @include breakpoint(max-lg) {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        min-height: auto;
        overflow-y: auto;
        max-width: 280px;
        transform: translateX(-101%);
        &.active {
            transform: translateX(0);
            z-index: 11;
        }
    }
    .sidebar-close {
        position: absolute;
        right: 5px;
        top: 5px;
        line-height: 1;
        padding: 6px;
        &:hover {
            background: $danger;
        }
    }
}
.dashboard-sidebar-header {
    background-color: $section-bg;
}
.dashboard-widget {
    padding: 30px;
}
.upload-icon {
    width: 25px;
    height: 25px;
    background: $base-color;
    @extend %flex-center;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    outline: 2px solid $white;
    color: $white;
}
.user {
    text-align: center;
    .user-thumb {
        width: 80px;
        aspect-ratio: 1;
        margin: 0 auto 13px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
}
.dashboard-menu {
    font-weight: 300;
    @include breakpoint(max-lg) {
        font-size: 15px;
    }
    li {
        padding: 7px 0;
        &.has-submenu {
            position: relative;
            &::before {
                // @include line-ico(f107);
                @extend %line-ico;
                content: "\f107";
                right: 0;
                top: 7px;
                font-size: 14px;
            }
        }
        a {
            display: block;
            &.active {
                color: $base-color;
            }
            i {
                margin-right: 7px;
                font-size: 19px;
            }
        }
    }
    .dashboard-submenu {
        padding: 5px 0;
        padding-left: 35px;
        font-size: 15px;
        display: none;
        @include breakpoint(max-lg) {
            font-size: 14px;
        }
    }
}
.dashbaord-sidebar-body {
    background-color: lighten($body-bg, 5%);
}

.dashboard-body {
    width: calc(100% - 300px);
    padding: 40px;
    @include breakpoint(max-xxl) {
        padding: 30px;
    }
    @include breakpoint(max-xl) {
        padding: 20px;
    }
    @include breakpoint(max-lg) {
        width: 100%;
    }
    @include breakpoint(max-md) {
        width: 100%;
        padding: 15px;
    }
}

// Card
.dashboard-card {
    padding: 35px;
    background: $gradient-five;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    @include media-max(1700px) {
        padding: 30px 25px;
    }
    &::before {
        @extend %position-set;
        background: url(./../images/shape/line.png) center no-repeat;
        background-position: left;
        top: 0;
        right: 0;
        opacity: 0.3;
        z-index: -1;
    }
    &__content {
        .title {
            font-weight: 500;
            color: $white;
            word-spacing: 2px;
            margin-bottom: 8px;
            @include media-max(1500px) {
                font-size: 15px;
            }
        }
        .value {
            @include media-max(1500px) {
                font-size: 25px;
            }
            span {
                font-family: $body;
            }
        }
    }
    &__icon {
        width: clamp(40px, 4vw, 80px);
        aspect-ratio: 1;
        border-radius: 50%;
        position: absolute;
        right: 5px;
        top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gradient-five;
        font-size: clamp(20px, 3vw, 45px);
        color: rgba($white, 0.3);
        box-shadow: 0 3px 8px rgba($base-color, 0.3);
        font-weight: 700;
    }
    .progress-wrapper {
        position: absolute;
        max-width: 100px;
        height: 15px;
        border-radius: 10px 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $body-bg;
        width: 100%;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        &::before {
            @extend %position;
            left: -18px;
            bottom: -2px;
            background: url(./../images/shape/round1.png);
            background-size: contain;
            width: 22px;
            height: 17px;
            z-index: 1;
        }
        &::after {
            @extend %position;
            right: -18px;
            bottom: -2px;
            background: url(./../images/shape/round1.png);
            transform: rotateY(-180deg);
            background-size: contain;
            width: 22px;
            height: 17px;
            z-index: 1;
        }
        .progress {
            z-index: 1;
            width: 75%;
        }
    }
}
.latest-transaction-wrapper {
    max-height: 300px;
    overflow-y: auto;
}
.latest-transaction {
    padding: 15px;
    border-radius: 7px;
    background: $body-bg;
    &:not(&:last-child) {
        margin-bottom: 15px;
    }
    .left-side {
        width: calc(100% - 100px);
        .title {
            font-weight: 500;
        }
        .des {
            font-size: 14px;
            @include text-wrap;
            -webkit-line-clamp: 1;
        }
    }
    .price {
        width: 100px;
        text-align: right;
    }
}
.deposit-card {
    img {
        width: 100%;
    }
    .card-footer {
        background: transparent;
        padding-top: 0;
        .cmn--btn {
            width: 100%;
            @include breakpoint(max-lg) {
                padding: 7px 25px !important;
            }
        }
    }
}

// Transaction Preview
.deposit-preview {
    @extend %flex-center;
    padding: 30px;
    border: 2px solid rgba($base-color, 0.3);
}

.deposit-preview .deposit-thumb {
    width: 40%;
}

.deposit-preview .deposit-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.deposit-preview .deposit-content {
    width: 60%;
    border-left: none;
    border-radius: 0 5px 5px 0;
    padding-left: 30px;
    @extend %flex;
}

@media (max-width: 1199px) {
    .deposit-preview .deposit-content ul li {
        font-size: 14px !important;
    }
    .deposit-preview {
        padding: 30px 20px;
    }
}

.deposit-preview .deposit-content ul {
    width: 100%;
    text-align: center;
}

.deposit-preview .deposit-content ul li {
    padding: 10px 0;
    font-size: 16px;
    @extend %flex;
    justify-content: space-between;
    align-items: center;
}

.deposit-preview .deposit-content ul li:not(:last-child) {
    border-bottom: 1px dashed $border-color;
}

@media (max-width: 767px) {
    .deposit-preview {
        margin-left: auto;
        margin-right: auto;
        max-width: 400px;
        padding: 20px;
    }

    .deposit-preview .deposit-thumb {
        width: 100%;
    }

    .deposit-preview .deposit-content {
        padding-left: 0;
        padding-top: 20px;
        width: 100%;
        border-radius: 0 0 5px 5px;
    }
}

@media (max-width: 450px) {
    .deposit-preview {
        padding: 15px;
    }
    .deposit-preview .deposit-content li {
        font-size: 14px !important;
    }
}

.dashboard-top {
    background-color: lighten($color: $body-bg, $amount: 5%);
    padding: 10px 30px;
    @include breakpoint(max-xl) {
        padding: 10px 20px;
    }
    @include breakpoint(max-md) {
        padding: 10px 15px;
    }
    .logo {
        img {
            max-width: 120px;
            @include breakpoint(max-xs) {
                max-width: 80px;
            }
        }
    }
    .user-thumb {
        width: 40px;
    }
}
.fs--14px {
    font-size: 14px;
}
.account-menu {
    position: relative;
    &:hover {
        .account-submenu {
            visibility: visible;
            opacity: 1;
            transform: translate(0);
        }
    }
    .account-submenu {
        position: absolute;
        width: 170px;
        right: 0;
        top: 100%;
        text-align: left;
        background: $section-bg;
        padding: 12px;
        font-size: 14px;
        z-index: 11;
        border-radius: 5px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-20px);
        @extend %transition;
        box-shadow: 0 3px 15px rgba($base-color, 0.3);
        li {
            padding: 3px 0;
            a {
                display: block;
            }
        }
    }
}

.dashboard-sidebar-toggler {
    font-size: 24px;
}
