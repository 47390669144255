.position-relative {
    .container {
        position: relative;
        z-index: 1;
    }
}
.banner-section {
    position: relative;
    padding: 220px 0;
    @include breakpoint(max-xl) {
        padding: 150px 0;
    }
    @include breakpoint(max-md) {
        padding: 100px 0;
    }
    .shape {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        opacity: 0.5;
        img {
            width: 100%;
        }
    }
}

.banner-content {
    max-width: 880px;
    margin: 0 auto;
    text-align: center;
    .title {
        font-weight: 600;
    }
    .banner-pera {
        font-size: 24px;
        font-weight: 300;
        max-width: 720px;
        margin: 0 auto;
        margin-top: 40px;
        @include breakpoint(max-xl) {
            margin-top: 30px;
        }
        @include breakpoint(max-md) {
            font-size: 20px;
            margin-top: 20px;
        }
        @include breakpoint(max-sm) {
            margin-top: 20px;
        }
    }
    .search-wrapper {
        max-width: 650px;
        margin: 0 auto;
        .form--control {
            height: 60px;
            background: $white;
        }
    }
}

// Inner Banner Section Starts
.inner-banner {
    padding: 80px 0;
    position: relative;
    @include breakpoint(max-md) {
        padding: 60px 0;
    }
    @include breakpoint(max-xs) {
        padding: 50px 0;
    }
    &::before {
        @extend %position-set;
        background: $body-bg;
        opacity: 0.8;
    }
    .container {
        position: relative;
        z-index: 1;
    }
}
.breadcrumb {
    gap: 10px 15px;
    font-size: 17px;
    li:nth-child(1) {
        position: relative;
        padding-right: 25px;
        &::before {
            @extend %line-ico;
            content: '\f061';
            right: 0;
            top: 3px;
        }
    }
}
