.custom--modal .modal-footer,
.custom--modal .modal-body,
.custom--modal .modal-header {
    background: $body-bg;
    border-color: $border-color !important;
}
.custom--modal .modal-body {
    background: $section-bg;
}

.custom--modal .modal-footer .modal-title,
.custom--modal .modal-header .modal-title {
    margin: 0;
    color: $title-color;
}

.custom--modal .modal-content {
    border: 0;
    // box-shadow: 0 5px 8px rgba($base-color, 0.3);
}
