/* background color css start */
.bg--primary {
    background-color: $primary !important;
}
.bg--secondary {
    background-color: $secondary !important;
}
.bg--success {
    background-color: $success !important;
}
.bg--danger {
    background-color: $danger !important;
}
.bg--warning {
    background-color: $warning !important;
}
.bg--info {
    background-color: $info !important;
}
.bg--dark {
    background-color: $dark !important;
}
.bg--light {
    background-color: $light !important;
}
.bg--base {
    background-color: $base-color !important;
}
.bg--section {
    background-color: $section-bg !important;
}
.bg--body {
    background-color: $body-bg !important;
}
/* background color css end */

.theme-one {
    color: $theme-one !important;
}
.theme-one-bg {
    background: $theme-one !important;
}
.theme-two {
    color: $theme-two !important;
}
.theme-two-bg {
    background: $theme-two !important;
}
.theme-three {
    color: $theme-three !important;
}
.theme-three-bg {
    background: $theme-three !important;
}
.theme-four {
    color: $theme-four !important;
}
.theme-four-bg {
    background: $theme-four !important;
}
