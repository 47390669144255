// Font-family
$heading: "Raleway", sans-serif;
$body: "Roboto", sans-serif;

// Title Color
$title-color: #ffffff;

// Essential Colors
$base-color: #f23935;
$base-color: #1f71fd;
$base-color: #4169e1;
$base-color: #03b4ed;

$accent-color: #160c66;

$body-color: rgb(214, 222, 233);
$border-color: rgba(255, 255, 255, 0.2);

$body-bg: #4c11b8;
$section-bg: #410ca1;

// $body-bg: #440ea7;
// $section-bg: #36098a;

$white: #fff;

$theme-one: #ff3653;
$theme-two: #18d39e;
$theme-three: #0c93d2;
$theme-four: #f6a200;
$theme-five: #09389e;

// Color variable for Bootstrap
$primary: #7367f0;
$secondary: #868e96;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #082032;
$light: #bcc7da;
$muted: #999a9e;

// Gradient Color
$gradient-one: linear-gradient(117deg, #e45340 0%, #d502fa 100%);
$gradient-two: linear-gradient(117deg, #ffac48 0%, #ff458b 100%);
$gradient-three: linear-gradient(117deg, #2fe4c0 0%, #319b92 100%);
$gradient-four: linear-gradient(117deg, #5bf1fe 0%, #4fb0ff 100%);
$gradient-five: linear-gradient(117deg, #589fff 0%, #596fff 100%);
